import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { GlobalApi } from "./GlobalApi";

declare global {
    interface IOri {
        /**
         * Global API Gateway Module
         */
        globalApi?: GlobalApi;
    }
}

const globalApi = Container.get(GlobalApi);

// publish for access from legacy JS code
ori.globalApi = globalApi;
ori.modules.push("globalApi");

// init from markup data on DOM ready
Container.get(ReadyHelper).bindDomReady(() => globalApi.init());
